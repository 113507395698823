<template>
    <b-container fluid>
      <b-row>
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle v-if="!propOpenedInModal">
              <h4 class="card-title">{{cvCardTitle}}</h4>
            </template>
            <template v-slot:body>
              <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
              <form action="#" v-if="vmGAME_HMFormData && Object.keys(vmGAME_HMFormData).length > 0">
                <div class="form-row">

    <div class="col-md-12 mb-3">
      <label for="validationword">
        {{cvWordLabel}}</label
      >
      <input
        v-model="vmGAME_HMFormData.word"
        type="text"
        class="form-control"
        required
      />
  </div>
    <div class="col-md-12 mb-3">
      <label for="validationdifficulty">
        {{cvDifficultyLabel}}</label
      >
      <input
        v-model="vmGAME_HMFormData.difficulty"
        type="text"
        class="form-control"
        required
      />
  </div>
                </div>
                <div class="form-group">
                  <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                     <b-spinner label="Spinning"></b-spinner>
                  </button>
                  <button type="button" class="btn btn-primary" @click="game_hmEdit()">{{cvSubmitBtn}}</button>
                </div>
              </form>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
        <div v-html="toastMsg"></div>
      </b-toast>
    </b-container>
  </template>

<script>

import { GameHm } from "../../../FackApi/api/gameHm"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "GAME_HMEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propGAME_HMObj: {
      type: Object,
      default: function () {
        return {
          "word": "",
          "difficulty": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit Game Hm",
      cvCardSubHeader: "Edit Game Hm",
      cvSubmitBtn: "Edit",
      cvWordLabel: "word",
      cvDifficultyLabel: "difficulty",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GAME_HM",
      vmGAME_HMFormData: {}
    }
  },
  mounted () {
    this.game_hmView()
  },
  methods: {
    /**
       * validate
       */
    validate () {
      try {
        for (let index in this.vmGAME_HMFormData) {
          if (!this.vmGAME_HMFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
       * game_hmView
       */
    async game_hmView () {
      try {
        if (this.propOpenedInModal) {
          this.vmGAME_HMFormData = this.propGAME_HMObj
        }
        else {
          let gameHmId = this.$route.params.game_hm_id
          let gameHmViewResp = await GameHm.game_hmView(this, gameHmId)

          if (gameHmViewResp && gameHmViewResp.resp_status) {
            this.vmGAME_HMFormData = gameHmViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at game_hmView() and Exception:", err.message)
      }
    },
    /**
       * game_hmEdit
       */
    async game_hmEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let gameHmAddResp = await GameHm.game_hmEdit(this, this.vmGAME_HMFormData)
        await ApiResponse.responseMessageDisplay(this, gameHmAddResp)

        if (gameHmAddResp && !gameHmAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseGAME_HMEditModal", this.vmGAME_HMFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at game_hmEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
